<mat-dialog-content> {{ message }} </mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    [hidden]="!this.accept"
    mat-raised-button
    color="primary"
    tabindex="-1"
    (click)="onConfirmClick()"
  >
    {{ acceptButtonText }}
  </button>

  <button
    mat-raised-button
    color="primary"
    tabindex="-2"
    (click)="onCancelClick()"
  >
    {{ cancelButtonText }}
  </button>
</mat-dialog-actions>
