import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "alert-dialog",
  templateUrl: "./alert-dialog.component.html"
})
export class AlertDialogComponent {
  public message: string;
  public cancelButtonText: string;
  public accept: boolean;
  public acceptButtonText: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialogRef: MatDialogRef<AlertDialogComponent>) {
    if (data) {
      this.message = data.message;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel;
      }
      if (data.accept == true) {
        this.accept = data.accept;
        this.acceptButtonText = data.buttonText.accept;
      }
    }
  }

  public onCancelClick(): void {
    if (!this.accept) {
      this.dialogRef.close(true);
    } else if (this.accept) {
      this.dialogRef.close(false);
    }
  }

  public onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
