import { Injectable } from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpResponse} from "@angular/common/http";
import { ConfigProvider } from "../shared/config.provider";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";

@Injectable()
export class HttpService {
  private apiRoot: string = null;

  constructor(
    private httpClient: HttpClient,
    private configProvider: ConfigProvider
  ) {
    this.apiRoot = this.configProvider.result.apiRoot + "/";
  }

  public get<T>(apiName: string, params?: any): Observable<T> {
    const apiUrl = this.apiRoot + apiName;
    return this.httpClient
      .get<T>(apiUrl, { params })
      .pipe(retry(3), catchError(this.handleError));
  }

  public post<T>(
    apiName: string,
    params?: unknown,
    autoRetry = true
  ): Observable<T> {
    const apiUrl = this.apiRoot + apiName;
    if (autoRetry) {
      return this.httpClient
        .post<T>(apiUrl, params)
        .pipe(retry(3), catchError(this.handleError));
    } else {
      return this.httpClient
        .post<T>(apiUrl, params)
        .pipe(catchError(this.handleError));
    }
  }
  
  public postWithResponse<T>(apiName: string, params?: unknown, autoRetry = true) : Observable<HttpResponse<T>> {
    const apiUrl = this.apiRoot + apiName;
    if (autoRetry) {
      return this.httpClient
          .post<T>(apiUrl, params, {observe: 'response'})
          .pipe(retry(3), catchError(this.handleError));
    } else {
      return this.httpClient
          .post<T>(apiUrl, params, {observe: 'response'})
          .pipe(catchError(this.handleError));
    }
  }

  public download(apiName: string, params?: unknown): Observable<Blob> {
    const apiUrl = this.apiRoot + apiName;
    return this.httpClient
      .post(apiUrl, params, { observe: "body", responseType: "blob" })
      .pipe(retry(3), catchError(this.handleError));
  }

  public put<T>(apiName: string, params?: unknown): Observable<T> {
    const apiUrl = this.apiRoot + apiName;
    return this.httpClient
      .put<T>(apiUrl, params)
      .pipe(retry(3), catchError(this.handleError));
  }

  public patch<T>(apiName: string, params?: unknown): Observable<T> {
    const apiUrl = this.apiRoot + apiName;
    return this.httpClient
      .patch<T>(apiUrl, params)
      .pipe(retry(3), catchError(this.handleError));
  }

  public delete<T>(apiName: string, id: string): Observable<T> {
    const apiUrl = this.apiRoot + apiName;
    return this.httpClient
      .delete<T>(apiUrl + "/" + id)
      .pipe(retry(3), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}