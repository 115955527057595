// Angular modules
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// 3rd party modules
import { AppInitializerModule, POST_APP_INITIALIZER } from "./auth/app.initializer.module";

const appRoutes: Routes = [
  {
    path: "",
    loadChildren: () => import("./master/master.module").then((m) => m.MasterModule),
    resolve: { authenticate: POST_APP_INITIALIZER }
  }
];

@NgModule({
  imports: [
    AppInitializerModule.forRoot(), // NOTE: The MSAL module will also take care of loading the ConfigProvider that can be used throughout the application.
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
