import { startOfToday, format } from "date-fns";

export function jsonToJsDate(jsonDate: Date): Date {
  return dateEmpty(jsonDate)
    ? null // else you get as date-time 01-01-1970T00:00:00z
    : new Date(jsonDate);
}

export function dateEmpty(inputDate: Date): unknown {
  return !inputDate || inputDate.toString() === "" ? true : false;
}

export function dateInFuture(inputDate: Date): unknown {
  return dateEmpty(inputDate) || inputDate > startOfToday() ? true : false;
}

export function formatDate(
  inputDate: Date,
  d_format = "dd-MM-yyyy"
): string {
  return inputDate ? format(inputDate, d_format) : null;
}

export function toDayFormatted(d_format = "yyyy-MM-dd"): string {
  return format(startOfToday(), d_format);
}

export function nowFormatted(
  df_format = "yyyy-MM-dd_HH.mm.ss"
): string {
  return format(new Date(), df_format);
}
