import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { RolesService } from "../roles/shared/roles.service";
import { User } from "../users/shared/user";
import { UsersService } from "../users/shared/users.service";

export const errorMissingPermission = "U heeft niet de juiste permissie";

@Injectable({ providedIn: "root" })
export class SecurityService {
  private currentRole: string;
  private permissions: string[];
  private userDetails: User;
  private loaded: Promise<void>;
  private deferredLoaded: { resolve(): void; reject(): void };

  constructor(private usersService: UsersService, private rolesService: RolesService, private authService: AuthService) {}

  public get UserDetails(): User {
    return this.userDetails;
  }

  public load(): Promise<void> {
    if (!this.loaded) {
      this.loaded = new Promise<void>((resolve, reject) => {
        this.deferredLoaded = { resolve: resolve, reject: reject };
      });

      // setUserDetails
      const userName = this.usersService.CurrentUser.username;
      if (!this.usersService.CurrentUser) {
        this.deferredLoaded.reject();
      }
      // const userName = this.authService.getUser().email;
      this.usersService.getUser(userName).subscribe((user) => {
        if (!user) {
          this.deferredLoaded.reject();
          return;
        }
        this.userDetails = user;

        // setUserRolesAndPermisions
        const userRoles = [];
        const userPermissions = [];

        const allRolesAndPermissions = [];
        this.rolesService.getRoles().subscribe((roles) => {
          roles.forEach((role) => {
            allRolesAndPermissions.push(role);
          });
          this.userDetails.roles.forEach((userRole) => {
            allRolesAndPermissions.forEach((role) => {
              if (role.name === userRole.name) {
                // userRole only contains the role name!
                userPermissions[role.name] = role.permissions;
                userRoles.push(role);
              }
            });
            const currentRole = userRoles[0].name; // Mark agreed to use the first role 20201118 (roles are sorted!)
            const currentPermissions = userPermissions[currentRole];
            this.setCurrentRole(currentRole);
            this.setPermissions(currentPermissions);
          });
          this.deferredLoaded.resolve();
        });
      });
    }
    return this.loaded;
  }

  public hasPermission(expectedPermission: string): boolean {
    return this.permissions?.includes(expectedPermission);
  }

  public getCurrentRole(): string {
    return this.currentRole;
  }

  public getPermissions(): string[] {
    return this.permissions;
  }

  public setCurrentRole(currentRole: string): void {
    this.currentRole = currentRole;
  }

  public setPermissions(permissions: string[]): void {
    this.permissions = permissions;
  }
}
