import { NgModule, APP_INITIALIZER, ModuleWithProviders, InjectionToken } from "@angular/core";

import { HTTP_INTERCEPTORS, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from "@angular/common/http";

// Azure MSAL
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { ConfigProvider } from "../shared/config.provider";
import { Observable } from "rxjs";
import { SecurityService } from "../shared/security.service";
import { AuthService } from "./auth.service";
import { UsersService } from "../users/shared/users.service";
import { SourcesService } from "../sources/shared/sources.service";
import { CategoryService } from "../categories/shared/category.service";
import { MatchingSourceAttributesService } from "../matching/matching-source-attributes/shared/matchingSourceAttributes.service";

export const POST_APP_INITIALIZER: InjectionToken<string> = new InjectionToken<string>("POST_APP_INITIALIZER");

export function initializerFactory(configProvider: ConfigProvider): unknown {
  return () => configProvider.load();
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(config: ConfigProvider): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.result.clientId,
      authority: config.result.authority,
      redirectUri: config.result.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false // Set to true for Internet Explorer 11
    }
  });
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(appConfig: ConfigProvider): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();

  for (const protectedResource of appConfig.result.protectedResources) {
    protectedResourceMap.set(protectedResource.endpoint, protectedResource.scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: protectedResourceMap
  };
}

export function MSALGuardConfigFactory(appConfig: ConfigProvider): MsalGuardConfiguration {
  const auth = {
    interactionType: InteractionType.Redirect,
    authRequest: appConfig.result.protectedResources
  };
  return auth as MsalGuardConfiguration;
}

export function postAppInitilizerFactory(securityService: SecurityService, usersService: UsersService, sourcesService: SourcesService, categoryService: CategoryService, matchingSourceAttributesService: MatchingSourceAttributesService): unknown {
  return async () => {
    let failed = false;
    await usersService.initializeCurrentUser().catch(() => {
      failed = true;
    });
    await securityService.load();
    if (failed) return null;
    return new Promise<void>((resolve) => {
      Promise.all([sourcesService.load(), categoryService.load(), matchingSourceAttributesService.load()]).then(() => resolve());
    });
  };
}

export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(public securityService: SecurityService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let currentRole = this.securityService.getCurrentRole();
    if (!currentRole || currentRole === "undefined") {
      currentRole = "Raadpleger";
    }

    let headers: HttpHeaders = req.headers;
    if (!req.headers.has("Puic-Role")) {
      headers = req.headers.append("Puic-Role", currentRole);
    }

    const authReq = req.clone({ headers: headers });
    return next.handle(authReq);
  }
}

@NgModule({
  providers: [],
  imports: [MsalModule]
})
export class AppInitializerModule {
  static forRoot(): ModuleWithProviders<AppInitializerModule> {
    return {
      ngModule: AppInitializerModule,
      providers: [
        ConfigProvider, // Here the ConfigProvider will be loaded for the entire application.
        {
          provide: APP_INITIALIZER,
          useFactory: initializerFactory,
          deps: [ConfigProvider],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHttpInterceptor,
          deps: [SecurityService],
          multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [ConfigProvider]
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [ConfigProvider]
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [ConfigProvider]
        },
        {
          provide: POST_APP_INITIALIZER,
          useFactory: postAppInitilizerFactory,
          deps: [SecurityService, UsersService, SourcesService, CategoryService, MatchingSourceAttributesService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        AuthService
      ]
    };
  }
}
