// Angular modules
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { MsalRedirectComponent } from "@azure/msal-angular";
// Own Components
import { AppComponent } from "./app.component";
import { KendoModule } from "./shared/kendo.module";
import { AppRoutingModule } from "./app.routing.module";
// Shared
import { HttpService } from "./shared/http.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpCancelService } from "./shared/httpCancel.service";
import { ManageHttpInterceptor } from "./shared/managehttp.interceptor";
import { MaterialModule } from "./shared/material.module";
import { ToastService } from "./shared/toast.service";
import { SecurityService } from "./shared/security.service";
import { LifeLineService } from "./core/lifeline.service";
import { AppInitializerModule } from "./auth/app.initializer.module";
import { LoadingSpinnerComponent } from "./shared/loading/loading-spinner/loading-spinner.component";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppInitializerModule.forRoot(), // NOTE: The MSAL module will also take care of loading the ConfigProvider that can be used throughout the application.
    KendoModule,
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "nl"
    },
    ToastService,
    SecurityService,
    LifeLineService,
    HttpService,
    HttpCancelService,
    LoadingSpinnerComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
