// Angular Modules
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// Own modules
import { HttpService } from "../../shared/http.service";
import {
  SourceAttribute,
  MatchingAttributes,
  SourceAttributeItem,
} from "./sourceAttribute";
import { ToastService } from "../../shared/toast.service";
import {
  SourceLocalKeysLimitation,
  SourceLocalKeysLimitationData,
} from "./sourceLocalKeysLimitations";

@Injectable({
  providedIn: "root",
})
export class SourcesService {
  private readonly apiName = "sourceAttributes";
  private sourceAttributes: SourceAttribute[];
  private matchingAttributes: MatchingAttributes[];
  private loadedSourceAttributes: boolean;
  private loadedMetadataAttributes: boolean;

  private loaded: Promise<void>;

  public get SourceAttributes(): SourceAttribute[] {
    return this.sourceAttributes;
  }
  public get MatchingAttributes(): MatchingAttributes[] {
    return this.matchingAttributes;
  }

  public load(): Promise<void> {
    if (!this.loaded) {
      this.loaded = new Promise<void>((resolve) => {
        this.getSourceAttributes().subscribe(
          (res) => {
            this.generateLabels(res);
            this.sourceAttributes = res;
            this.loadedSourceAttributes = true;
            if (this.bothLoaded()) {
              resolve();
            }
          },
          () => {
            this.toastService.showError(
              "Het ophalen van de bron attributen is mislukt"
            );
            this.loadedSourceAttributes = true;
            if (this.bothLoaded()) {
              resolve();
            }
          }
        );

        this.getMatchingAttributes().subscribe(
          (res) => {
            this.matchingAttributes = res;
            this.loadedMetadataAttributes = true;
            if (this.bothLoaded()) {
              resolve();
            }
          },
          () => {
            this.toastService.showError(
              "Het ophalen van de bron attributen is mislukt"
            );
            this.loadedMetadataAttributes = true;
            if (this.bothLoaded()) {
              resolve();
            }
          }
        );
      });
    }
    return this.loaded;
  }

  private generateLabels(
    sourceAttributes: SourceAttribute[]
  ): SourceAttribute[] {
    sourceAttributes.forEach((sourceAttribute) => {
      sourceAttribute.attributes.forEach((attribute) => {
        attribute.attributeLabel = this.getLabelString(
          sourceAttribute.source,
          attribute
        );
      });
    });
    return sourceAttributes;
  }

  private getLabelString(
    source: string,
    attribute: SourceAttributeItem
  ): string {
    if (source != "PUIC2") {
      attribute.attributeLabel += " - " + source;
    }
    return this.capitalizeFirstLetter(attribute.attributeLabel);
  }

  private capitalizeFirstLetter(label: string): string {
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  private bothLoaded(): boolean {
    return this.loadedSourceAttributes && this.loadedMetadataAttributes;
  }

  constructor(
    private httpService: HttpService,
    private toastService: ToastService
  ) {}

  private getSourceAttributes(): Observable<SourceAttribute[]> {
    return this.httpService.get<SourceAttribute[]>(this.apiName);
  }

  public getMatchingAttributes(): Observable<MatchingAttributes[]> {
    return this.httpService.get<MatchingAttributes[]>("metadata/objectTypes");
  }

  public getLocalKeyLimits(): Observable<SourceLocalKeysLimitationData[]> {
    return this.httpService.get<SourceLocalKeysLimitationData[]>(
      "sourceLocalKeysLimitations"
    );
  }

  public addLocalKeyLimits(
    sourceLimitConfig: SourceLocalKeysLimitation
  ): Observable<SourceLocalKeysLimitation[]> {
    return this.httpService.post<SourceLocalKeysLimitation[]>(
      "sourceLocalKeysLimitations",
      sourceLimitConfig
    );
  }

  public editLocalKeyLimits(
    sourceLimitConfig: SourceLocalKeysLimitation
  ): Observable<SourceLocalKeysLimitation[]> {
    return this.httpService.put<SourceLocalKeysLimitation[]>(
      "sourceLocalKeysLimitations",
      sourceLimitConfig
    );
  }

  public deleteLocalKeyLimits(
    sourceLimitConfig: SourceLocalKeysLimitation
  ): Observable<SourceLocalKeysLimitation[]> {
    return this.httpService.delete<SourceLocalKeysLimitation[]>(
      "sourceLocalKeysLimitations",
      sourceLimitConfig.id
    );
  }
}
