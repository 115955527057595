import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class ToastService {
  constructor(private snackBar: MatSnackBar) {}

  public showMessage(message: string, action = "OK"): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  public showSucces(message: string, action = "OK"): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: ["snackbar-success"],
    });
  }

  public showWarning(message: string, action = "OK"): void {
    this.snackBar.open(message, action, {
      duration: 30000,
      panelClass: ["snackbar-warning"],
    });
  }

  public showError(message: string, error?: Error, action = "OK"): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: ["snackbar-error"],
    });

    console.log(message, error);
  }
}
