import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { jsonToJsDate } from "../../shared/dateFunctions";
import { HttpService } from "../../shared/http.service";
import { ToastService } from "../../shared/toast.service";
import { Category } from "./category";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  private readonly apiName = "categories";
  private categories: Category[];
  private loaded: Promise<void>;

  constructor(
    private httpService: HttpService,
    private toastService: ToastService
  ) {}

  public get Categories(): Category[] {
    return this.categories;
  }

  public load(): Promise<void> {
    if (!this.loaded) {
      this.loaded = new Promise<void>((resolve) => {
        this.getCategories().subscribe(
          (res) => {
            this.categories = Array.isArray(res) ? res : [];
            this.categories.forEach((c) => {
              c.startDate = jsonToJsDate(c.startDate);
              c.endDate = jsonToJsDate(c.endDate);
            });
            resolve();
          },
          (error) => {
            this.toastService.showError(
              "Het ophalen van de categorieën is mislukt", error
            );
            resolve();
          }
        );
      });
    }
    return this.loaded;
  }

  public refreshCategories(): Promise<void> {
    this.loaded = null;
    return this.load();
  }

  public addCategory(category: Category): Observable<Category> {
    return this.httpService.post<Category>(this.apiName, category);
  }

  public editCategory(category: Category): Observable<Category> {
    return this.httpService.put<Category>(this.apiName, category);
  }

  public archiveCategory(category: Category): Observable<Category> {
    return this.httpService.delete<Category>(
      this.apiName,
      category.uniqueCategoryId
    );
  }

  private getCategories(): Observable<Category[]> {
    return this.httpService.get<Category[]>(this.apiName);
  }
}
