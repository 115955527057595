import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { Role } from "./role";
import { HttpService } from "../../shared/http.service";
import { map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  private allRolesAndPermissions: Role[] = [];
  private readonly apiName = "Authorization/roles";
  private rolesSubject: ReplaySubject<Role[]>;
  private roles: Observable<Role[]>;


  constructor(private httpService: HttpService) {}

  public getRoles(): Observable<Role[]> {
    if(!this.rolesSubject) {
      this.rolesSubject = new ReplaySubject<Role[]>(0);
      this.roles = this.rolesSubject.asObservable();
      this.httpService.get<Role[]>(this.apiName).subscribe((roles) => {
        this.rolesSubject.next(roles);
      });
    }
    return this.roles.pipe(take(1));
  }

  public addRole(role: Role): Observable<Role> {
    this.clearRoles();
    return this.httpService.post<Role>(this.apiName, role);
  }

  public editRole(role: Role): Observable<Role> {
    this.clearRoles();
    return this.httpService.put<Role>(this.apiName, role);
  }

  public deleteRole(role: Role): Observable<unknown> {
    this.clearRoles();
    return this.httpService.delete(this.apiName, role.id);
  }

  public setAllRoles(): Observable<unknown> {
    return this.getRoles().pipe(
      map((roles) => {
        return (this.allRolesAndPermissions = roles);
      })
    );
  }

  public roleExists(roleName: string): boolean {
    const found =
      this.allRolesAndPermissions.findIndex((obj) => obj.name == roleName) !=
      -1;
    return found;
  }

  public getRole(roleName: string): Role {
    return this.allRolesAndPermissions.find((obj) => obj.name === roleName);
  }

  private clearRoles() {
    this.rolesSubject = null;
  }
}
