import { Role } from "../../roles/shared/role";

export class User {
  id: string;
  principalId: string;
  name: string;
  username: string;
  email: string;
  roles: Role[];
  rolesString?: string;
  isActive: boolean;
}
