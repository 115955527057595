// Angular modules
import { Component, OnDestroy, OnInit } from "@angular/core";
// 3rd party modules
import { AuthService } from "./auth/auth.service";
import { User } from "./users/shared/user";
import { LifeLineService } from "./core/lifeline.service";
import { Role } from "./roles/shared/role";
import { SecurityService } from "./shared/security.service";
import { MsalBroadcastService } from "@azure/msal-angular";
import { filter } from "rxjs";
import { InteractionStatus } from "@azure/msal-browser";
import { UsersService } from "./users/shared/users.service";
// import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {
  public pageTitle: string;
  public sidenavWidth = 5;
  public userDetails: User = new User();
  public userPermissions: string[][];
  public userRoles: Role[];
  public currentRole: string; // Role;
  public currentPermissions: string[];
  public multipleRoles: boolean;
  public loggedIn = false;

  constructor(private authService: AuthService, private securityService: SecurityService, private usersService: UsersService, private lifeLineService: LifeLineService, private msalBroadcastService: MsalBroadcastService) {}

  public ngOnInit(): void {
    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(async () => {
      await this.authService.acquireToken();
    });
  }

  ngOnDestroy() {
    this.lifeLineService.stop();
  }

  logOut() {
    this.authService.logOut();
  }

  private setCurrentUserRoleAndPermissions() {
    this.currentPermissions = this.userPermissions[this.currentRole];
    this.securityService.setCurrentRole(this.currentRole);
    this.securityService.setPermissions(this.currentPermissions);
  }

  public onChangeRole(selectedRole: string) {
    this.currentRole = selectedRole;
    this.setCurrentUserRoleAndPermissions();
  }

  public increase(): void {
    this.sidenavWidth = 15;
  }
  public decrease(): void {
    this.sidenavWidth = 5;
  }
}
