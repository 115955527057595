import { HttpClient, HttpBackend } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AsyncSubject } from "rxjs";
import { Config } from "../shared/config";

@Injectable({ providedIn: "root" })
export class ConfigProvider {
  private appConfigPath = "/assets/environmentconfig/appConfig.json";
  public resultObservable = new AsyncSubject<Config>();
  public result: Config;
  private http: HttpClient;
  /*
    Note config.service.ts, constructor, we are not injecting HttpClient, because if you inject HttpClient then Angular first resolves all the HTTP_INTERCEPTORS,
    and when you use MsalInterceptor in app module, this makes Angular to load MsalService and other component used by Msalinterceptor load before APP_INITIALIZER.
    To resolve this issue we need to by pass HTTP_INTERCEPTORS, so for this we can use HttpBackend handler, and then create local instance of HttpClient in config service constructor.
    This will bypass the HTTP_INTERCEPTORS, while getting config file.
    see: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1403
  */
  constructor(private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
  }

  public load(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .get<Config>(this.appConfigPath)
        .toPromise()
        .then((config) => {
          this.result = config;
          resolve(true);
        })
        .catch((response: Error) => {
          reject("Could not load file " + this.appConfigPath + ": " + JSON.stringify(response) + "");
        });
    });
  }
}
