// Angular Modules
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// Own modules
import { User } from "./user";
import { HttpService } from "../../shared/http.service";
import { AuthService } from "../../auth/auth.service";
import { MsalService } from "@azure/msal-angular";

@Injectable({
  providedIn: "root"
})
export class UsersService {
  private readonly apiName = "Authorization/users";
  private currentUser: User;

  constructor(private httpService: HttpService, private authService: AuthService, private msalService: MsalService) {}

  public get CurrentUser(): User {
    return this.currentUser;
  }

  public initializeCurrentUser(): Promise<void> {
    return new Promise<void>((resolve) => {
      let account = this.msalService.instance.getActiveAccount();
      if (account) {
        this.msalService.getLogger().verbose("Interceptor - active account selected");
      } else {
        this.msalService.getLogger().verbose("Interceptor - no active account, fallback to first account");
        const accounts = this.msalService.instance.getAllAccounts();
        account = accounts[0];
      }

      this.getUser(account.username).subscribe((user) => {
        this.currentUser = user;
        resolve();
      });
    });
  }

  public syncUsersWithAAD(): Observable<unknown> {
    return this.httpService.get("Authorization/synchronize");
  }

  public getUsers(): Observable<User[]> {
    return this.httpService.get<User[]>(this.apiName);
  }

  public getUser(userName: string): Observable<User> {
    return this.httpService.get<User>(this.apiName + "/" + userName);
  }

  public editUser(user: User): Observable<User> {
    return this.httpService.put<User>(this.apiName, user);
  }
}
