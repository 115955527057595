import { NgModule } from "@angular/core";
import { MenuModule } from "@progress/kendo-angular-menu";
import { GridModule } from "@progress/kendo-angular-grid";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { IntlModule } from "@progress/kendo-angular-intl";
import { PopupModule } from "@progress/kendo-angular-popup";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { UploadsModule } from '@progress/kendo-angular-upload';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import "@progress/kendo-angular-intl/locales/nl/all";
import "hammerjs";

@NgModule({
  imports: [
    ChartsModule,
    DateInputsModule,
    DropDownsModule,
    GridModule,
    InputsModule,
    IntlModule,
    MenuModule,
    PopupModule,
    TreeViewModule,
    UploadsModule,
    IconsModule,
    LayoutModule,
    IndicatorsModule,
    NavigationModule
  ],
  exports: [
    ChartsModule,
    DateInputsModule,
    DropDownsModule,
    GridModule,
    InputsModule,
    IntlModule,
    MenuModule,
    PopupModule,
    TreeViewModule,
    UploadsModule
  ],
})
export class KendoModule {}
