import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertDialogComponent } from "../core/alert-dialog/alert-dialog.component";
import { HttpService } from "../shared/http.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Injectable()
export class LifeLineService {
  private readonly checkLifeLineMilliseconds: number = 1000000;
  private interval: any;
  public alive = true;
  private dialogRef;
  private lostConnectionDialogIsOpen = false;
  private connectionRestoredDialogIsOpen = false;

  constructor(
    // private translate: TranslateService,
    private dialog: MatDialog,
    private httpService: HttpService
  ) {}

  public start() {
    this.interval = setInterval(() => {
      this.checkLifeLine();
    }, this.checkLifeLineMilliseconds);
  }

  public stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  lostConnectionDialog() {
    this.dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message:
          "De verbinding met de server is onverwachts verbroken. U krijgt een melding als de verbinding weer hersteld is.",
        buttonText: {
          cancel: "Sluiten",
        },
        accept: false,
      },
    });
    this.lostConnectionDialogIsOpen = true;
  }

  connectionRestoredDialog() {
    this.dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message:
          "De verbinding met de server is hersteld. Herstart de applicatie als deze niet meer werkt.",
        buttonText: {
          cancel: "Sluiten",
        },
        accept: false,
      },
    });
    this.connectionRestoredDialogIsOpen = true;
  }

  public checkLifeLine(): Promise<void> {
    return this.httpService
      .get("lifeline")
      .pipe(untilDestroyed(this))
      .toPromise()
      .then(() => {
        if (!this.alive) {
          if (this.lostConnectionDialogIsOpen) {
            this.dialogRef.close();
            this.lostConnectionDialogIsOpen = false;
          }
          this.connectionRestoredDialog();
        }
        this.alive = true;
      })
      .catch(() => {
        if (this.alive) {
          if (this.connectionRestoredDialogIsOpen) {
            this.dialogRef.close();
            this.connectionRestoredDialogIsOpen = false;
          }
          this.lostConnectionDialog();
        }
        this.alive = false;
      });
  }
}
