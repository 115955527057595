import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import { BehaviorSubject, catchError, EMPTY, filter, firstValueFrom, of, switchMap } from "rxjs";
import { User } from "../users/shared/user";
import { Role } from "../roles/shared/role";
import { ConfigProvider } from "../shared/config.provider";

@Injectable()
export class AuthService {
  private user!: User;
  public role!: Role;
  private isInitializedSubject = new BehaviorSubject<boolean>(false);
  public get isInitialized(): boolean {
    return this.isInitializedSubject.getValue();
  }

  public isInitialized$ = this.isInitializedSubject.pipe(filter((x) => x));

  constructor(private httpClient: HttpClient, private msalService: MsalService, private configProvider: ConfigProvider) {}

  public async initializeUser(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<User>(this.configProvider.result.apiRoot + "/authorization/users/" + this.msalService.acquireTokenRedirect, {}).subscribe({
        next: (user: User) => {
          this.user = user;
          this.role = user.roles[0];
          this.isInitializedSubject.next(true);
          resolve();
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  public getUser() {
    if (this.user) {
      return this.user;
    }
  }

  public async acquireToken(): Promise<string> {
    // Sets account as active account or first account
    let account = this.msalService.instance.getActiveAccount();
    if (account) {
      this.msalService.getLogger().verbose("Interceptor - active account selected");
    } else {
      this.msalService.getLogger().verbose("Interceptor - no active account, fallback to first account");
      const accounts = this.msalService.instance.getAllAccounts();
      account = accounts[0];
    }

    let scopes : string[];
    if (this.configProvider.result.protectedResources) {
      scopes = this.configProvider.result.protectedResources[0].scopes;
    }
    
    const request: SilentRequest = {
      scopes,
      account
    };

    const authenticationResult = await firstValueFrom(
      this.msalService.acquireTokenSilent(request).pipe(
        catchError(() => {
          this.msalService.getLogger().error("Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve.");
          this.msalService.acquireTokenRedirect(request);

          return EMPTY;
        }),
        switchMap((result: AuthenticationResult) => {
          return of(result);
        })
      )
    );

    return authenticationResult.accessToken;
  }

  logOut() {
    // Add log out function here
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: "http://localhost:4300"
    });
  }
}
