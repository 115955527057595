import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatchingSourceAttributeConfiguration } from "./matchingSourceAttributes";
import { HttpService } from "../../../shared/http.service";
import { ToastService } from "../../../shared/toast.service";

@Injectable({
  providedIn: "root",
})
export class MatchingSourceAttributesService {
  private readonly apiName = "matchingSourceAttributes";
  private matchingSourceAttributeConfigurations: MatchingSourceAttributeConfiguration[];
  private loaded: Promise<void>;

  constructor(
    private httpService: HttpService,
    private toastService: ToastService
  ) {}

  public get MatchingSourceAttributeConfiguration(): MatchingSourceAttributeConfiguration[] {
    return this.matchingSourceAttributeConfigurations;
  }

  public load(): Promise<void> {
    if (!this.loaded) {
      this.loaded = new Promise<void>((resolve) => {
        this.getMatchingSourceAttributesColumnConfigurations().subscribe(
          (res) => {
            this.matchingSourceAttributeConfigurations = Array.isArray(res)
              ? res
              : [];
            resolve();
          },
          (error) => {
            this.toastService.showError(
              "Het ophalen van de matching configuraties is mislukt",
              error
            );
            resolve();
          }
        );
      });
    }
    return this.loaded;
  }

  public refreshMatchingConfigurations(): Promise<void> {
    this.loaded = null;
    return this.load();
  }

  private getMatchingSourceAttributesColumnConfigurations(): Observable<
    MatchingSourceAttributeConfiguration[]
  > {
    return this.httpService.get<MatchingSourceAttributeConfiguration[]>(
      this.apiName
    );
  }

  public addMatchingSourceAttributes(
    sourceAttribute: MatchingSourceAttributeConfiguration
  ): Observable<MatchingSourceAttributeConfiguration> {
    return this.httpService.post<MatchingSourceAttributeConfiguration>(
      this.apiName,
      sourceAttribute
    );
  }

  public editMatchingSourceAttributes(
    matchingSourceAttributeConfig: MatchingSourceAttributeConfiguration
  ): Observable<MatchingSourceAttributeConfiguration> {
    return this.httpService.put<MatchingSourceAttributeConfiguration>(
      this.apiName,
      matchingSourceAttributeConfig
    );
  }

  public deleteMatchingSourceAttributes(
    matchingSourceAttributeConfig: MatchingSourceAttributeConfiguration
  ): Observable<MatchingSourceAttributeConfiguration> {
    return this.httpService.delete<MatchingSourceAttributeConfiguration>(
      this.apiName,
      matchingSourceAttributeConfig.id
    );
  }
}
